import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'InformationData',
    component: () => import('../views/information.vue'),
    meta: {
      title: '数据一览'
    }
  },
  {
    path: '/drawMaterials',
    name: 'DrawMaterialsWS',
    component: () => import('../views/dashboard/drawMaterials.vue'),
    meta: {
      title: '取材工作站'
    }
  },
  // {
  //   path: '/embedding',
  //   name: 'EmbeddingWS',
  //   component: () => import('../views/dashboard/embedding.vue'),
  //   meta: {
  //     title: '包埋工作站'
  //   }
  // },
  {
    path: '/filmProduction',
    name: 'FilmProductionWS',
    component: () => import('../views/dashboard/filmProduction.vue'),
    meta: {
      title: '组织制片工作站'
    }
  },
  {
    path: '/filmProductionCell',
    name: 'FilmProductionCellWS',
    component: () => import('../views/dashboard/filmProductionCell.vue'),
    meta: {
      title: '细胞制片工作站'
    }
  },
  {
    path: '/scan',
    name: 'ScanWS',
    component: () => import('../views/dashboard/scan.vue'),
    meta: {
      title: '扫描工作站'
    }
  },
  {
    path: '/detection',
    name: 'DetectionWS',
    component: () => import('../views/dashboard/detection.vue'),
    meta: {
      title: '检测工作站'
    }
  },
  {
    path: '/pretreatment',
    name: 'PretreatmentWS',
    component: () => import('../views/dashboard/pretreatment.vue'),
    meta: {
      title: '前处理工作站'
    }
  },
  {
    path: '/MedicalUniversity',
    name: 'MedicalUniversity',
    component: () => import('../views/medicalUniversity/medicalUniversity.vue'),
    meta: {
      title: '医大数据一览'
    }
  },
  {
    path: '/registerHos',
    name: 'RegisterHos',
    component: () => import('../views/medicalUniversity/smallScreen/registerHos.vue'),
    meta: {
      title: '登记工作站'
    }
  },
  {
    path: '/detectionHos',
    name: 'DetectionHos',
    component: () => import('../views/medicalUniversity/smallScreen/detectionHos.vue'),
    meta: {
      title: '检测工作站'
    }
  },
  {
    path: '/drawMaterialsHos',
    name: 'DrawMaterialsHos',
    component: () => import('../views/medicalUniversity/smallScreen/drawMaterialsHos.vue'),
    meta: {
      title: '取材工作站'
    }
  },
  {
    path: '/embeddingHos',
    name: 'EmbeddingHos',
    component: () => import('../views/medicalUniversity/smallScreen/embeddingHos.vue'),
    meta: {
      title: '包埋工作站'
    }
  },
  {
    path: '/filmProductionCellHos',
    name: 'FilmProductionCellHos',
    component: () => import('../views/medicalUniversity/smallScreen/filmProductionCellHos.vue'),
    meta: {
      title: '细胞制片工作站'
    }
  },
  {
    path: '/filmProductionHos',
    name: 'FilmProductionHos',
    component: () => import('../views/medicalUniversity/smallScreen/filmProductionHos.vue'),
    meta: {
      title: '组织制片工作站'
    }
  },
  {
    path: '/scanHos',
    name: 'ScanHos',
    component: () => import('../views/medicalUniversity/smallScreen/scanHos.vue'),
    meta: {
      title: '扫描工作站'
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
